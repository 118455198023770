//import Web3Connect from 'web3connect';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
//import Portis from '@portis/web3';
import Fortmatic from 'fortmatic';

const {infura, portisKey, fortmaticKeys} = require('../utils').keys;

const web3Connect = new Web3Modal({
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: infura
      }
    },
    /*
    portis: {
      package: Portis,
      options: {
        id: portisKey()
      }
    },
    */
    fortmatic: {
      package: Fortmatic,
      options: {
        key: fortmaticKeys()
      }
    }
  }
});

export default web3Connect;
