<template>

  <div class="mt-4 editions-wrap">

    <div class="row pb-2 mt-3" v-if="activeAccount">
      <div class="col-12">
        <span>
          <account-profile :eth-address="activeAccount"
                           :total-owned-tokens="totalOwnedTokens">
          </account-profile>
        </span>
        <span v-if="isLoggedInAsCurrentAccount" class="pl-2">
          <button @click="web3Auth" class="btn btn-sm btn-primary">Notifications</button>
        </span>
      </div>
    </div>
    <div v-else>
      <div class="col-12">
        <account-not-found-msg class="mt-2"></account-not-found-msg>
      </div>
    </div>

    <b-tabs>

      <b-tab title="Collection" active>

        <div v-if="!isLoading && totalOwnedTokens === 0"
             class="row justify-content-sm-center no-assets">
          <div class="col col-sm-6 text-center mt-4">
            <code>No artworks collected yet</code>
          </div>
        </div>

        <div v-if="isLoading" class="row justify-content-sm-center">
          <div class="col col-sm-6 text-center mt-4">
            <loading-spinner></loading-spinner>
          </div>
        </div>

        <!-- V2 -->
        <div class="row editions-wrap mt-3" v-cloak>
          <div class="card-deck">

            <!--
            <div class="col-sm-4 mb-5"
                 v-for="edition in mintWonEditions"
                 :key="edition.tokenId">
              <gallery-card :edition="edition"
                            :editionNumber="edition.edition">

                <div class='neumorph text-right' v-if='isLoggedInAsCurrentAccount' @click="acceptHighBid(edition)">
                  <a class='btn btn-primary'>🎁  Unwrap to transfer to wallet</a>
                </div>

              </gallery-card>
            </div>
            -->

            <div class="col-sm-4 mb-5"
                 v-for="edition in limitBy(sortedAssets, currentListV2)"
                 :key="edition.tokenId">
              <gallery-card :edition="edition"
                            :editionNumber="edition.edition">

                <div class='neumorph text-right' v-if='isLoggedInAsCurrentAccount && edition.mintWon' @click="acceptHighBid(edition)">
                  <a class='btn btn-primary'>🎁  Unwrap to transfer to wallet</a>
                </div>

              </gallery-card>
            </div>


          </div>
        </div>

        <div class="row editions-wrap pt-1 pb-4" v-if="canShowMoreV2">
          <div class="col-12 text-center">
            <button @click="showMoreV2" class="btn btn-outline-primary mt-1 mb-5 ml-3 mr-3">
              Show more
            </button>
          </div>
        </div>

        <!-- V1 -->
        <div class="row editions-wrap mt-2" v-if="kodaV1Assets.length > 0" v-cloak>

          <div class="col-12">
            <h5 class="mb-3">KODA V1 (alpha)</h5>
          </div>

          <div class="col-sm-4 mb-5"
               v-for="edition in limitBy(kodaV1Assets, currentListV1)" :key="edition.tokenId"
               v-if="edition.active">
            <account-card :edition="edition"></account-card>
          </div>
        </div>

        <div class="row editions-wrap pt-1 pb-4" v-if="canShowMoreV1">
          <div class="col-12 text-center">
            <button @click="showMoreV1" class="btn btn-outline-primary mt-1 mb-5 ml-3 mr-3">
              Show more
            </button>
          </div>
        </div>

      </b-tab>

      <!-- Only show favourites if you are logged in and viewing your own profile -->
      <b-tab title="Favourites" v-if="isLoggedInAsCurrentAccount">

        <account-favorites class="mt-3"></account-favorites>

      </b-tab>

      <!-- Only show activity if you are logged in and viewing your own profile -->
      <b-tab title="Activity" v-if="isLoggedInAsCurrentAccount">

        <account-activity class="mt-3"></account-activity>

      </b-tab>

    </b-tabs>
  </div>

</template>

<script>

  import {mapGetters, mapState} from 'vuex';
  import {PAGES} from '../store/loadingPageState';
  import GalleryCard from '../components/cards/GalleryCard';
  import AccountActivity from '../components/account/AccountActivity';
  import AccountFavorites from '../components/account/AccountFavorites';
  import AccountProfile from '../components/account/AccountProfile';
  import LoadingSpinner from '../components/generic/LoadingSpinner';
  import * as actions from '../store/actions';
  import * as mutation from '../store/mutation';
  import AccountNotFoundMsg from '../components/account/AccountNotFoundMsg';
  import AccountCard from '../components/v1/AccountCard';
  import ManageUserProfile from '../components/modal/ManageUserProfile.vue';
  import * as _ from 'lodash';
  import { BTab, BTabs } from 'bootstrap-vue';

  export default {
    name: 'account',
    components: {
      AccountCard,
      AccountNotFoundMsg,
      LoadingSpinner,
      AccountProfile,
      AccountFavorites,
      AccountActivity,
      GalleryCard,
      ...{ BTab, BTabs }
    },
    data() {
      return {
        PAGES: PAGES,
        currentListV1: 12,
        currentListV2: 20,
        kodaV1Assets: [],
        kodaV2Assets: {},
        activityEditions: [],
        isLoading: false,
        activeAccount: null
      };
    },
    methods: {
      getMintWonEditions(editions) {
        return _(this.auction).pick(editions).pickBy(e => {
          const reEthAddress = RegExp(this.activeAccount, 'i')
          const isHighBidder = reEthAddress.test(e.highestBidder)
          const isOwner      = reEthAddress.test(e.owner)
          return isHighBidder && !isOwner
        }).map(e => _.merge(e, {mintWon: true})).keyBy('edition').value()
      },
      mintWon(edition) {
        const auction =  this.auction[edition.edition]
        //console.log(auction, this.auction)
        return auction && auction.mintWon;
      },
      acceptHighBid(edition) {
        this.$store.dispatch(`auction/${actions.ACCEPT_BID}`, edition).then(() => {
          const asset = this.kodaV2Assets[edition.edition]
          this.kodaV2Assets[edition.edition] = {
            ...asset,
            mintWon: false
          }
          //this.mintWonEditions = _.pull(this.mintWonEditions, edition)
          //this.kodaV2Assets.unshift(edition)
        })
      },
      showMoreV1() {
        this.currentListV1 += 12;
      },
      showMoreV2() {
        this.currentListV2 += 20;
      },
      web3Auth() {
        this.$ga.event('account-page', 'notification-button', 'show-popup');

        this.$store.dispatch(`auth/${actions.WEB3_AUTH_LOGIN}`)
          .then(() => {

            // load email address for token
            this.$store.dispatch(`auth/${actions.WEB3_AUTH_LOAD_PROFILE}`)
              .then((profile) => {

                // Present user model
                this.$modal.show(ManageUserProfile, {profile}, {
                  draggable: false,
                  resizable: false,
                  clickToClose: false,
                  scrollable: true,
                  adaptive: true,
                  height: 'auto',
                  minWidth: 300,
                  maxWidth: 600,
                });
              });
            console.log('load and populate email address');
          });
      },
      loadAccountTokens(ethAddress) {
        this.isLoading = true;
        this.activeAccount = ethAddress;

        const now = Math.round((new Date()).getTime() / 1000)

        this.eventService.loadAddressAuctionActivity(ethAddress)
          .then(({data}) => {
            const editions = _.map(data, '_args._tokenId').map(t => parseInt(t) - 1)
            this.$store.dispatch(`auction/${actions.GET_AUCTION_DETAILS_FOR_EDITIONS}`, editions).then(() => {
              this.mintWonEditions = this.getMintWonEditions(editions);
              this.kodaV2Assets = {
                ...this.kodaV2Assets,
                ...this.getMintWonEditions(editions)
              }
            })
            //this.activityEditions = editions;
          })

        this.accountService.getAccountTokens(ethAddress)
          .then((results) => {
            this.kodaV1Assets = results.kodaV1 || [];
            //this.kodaV2Assets = results.kodaV2 || [];
            this.kodaV2Assets = {
              ...this.kodaV2Assets,
              ..._.keyBy((results.kodaV2 || {}), 'edition')
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    computed: {
      ...mapState([
        'accountService',
        'eventService',
        'account',
        'currentNetworkId',
        'smartwallet',
        'auction'
      ]),
      ...mapState('auction', [
        'auction' 
      ]),
      ...mapState('kodaV2', {
        editionsMap: 'assets'
      }),
      ...mapGetters([
        'findArtist'
      ]),
      sortedAssets() {
        return _.sortBy(this.kodaV2Assets, ['mintWon', 'edition'])
      },
      isLoggedInAsCurrentAccount() {
        const routeAddress = _.get(this.$route.params, 'address');
        const { account, smartwallet } = this
        console.log(account, _.get(smartwallet, 'address'), routeAddress)
        return RegExp(`${account}|${_.get(smartwallet, 'address')}`, 'i').test(routeAddress)
      },
      totalOwnedTokens() {
        return _.size(this.kodaV1Assets) + _.size(this.kodaV2Assets);
      },
      canShowMoreV1() {
        const totalAvailable = _.size(this.kodaV1Assets);
        if (totalAvailable === 0) {
          return false;
        }
        return totalAvailable > this.currentListV1;
      },
      canShowMoreV2() {
        const totalAvailable = _.size(this.kodaV2Assets);
        if (totalAvailable === 0) {
          return false;
        }
        return totalAvailable > this.currentListV2;
      },
    },
    created() {
      const routeAddress = _.get(this.$route.params, 'address');
      //this.isLoggedInAsCurrentAccount = this.currentAccountLoggedIn()
      this.loadAccountTokens(routeAddress);
    },
    watch: {
      // Watch for account changes to see if the current user logs in
      'currentNetworkId': function (newVal) {
        const routeAddress = _.get(this.$route.params, 'address');
        //this.isLoggedInAsCurrentAccount = this.currentAccountLoggedIn()
        this.loadAccountTokens(routeAddress);
      },
      'account': function (newVal) {
        console.log('account changed')
        //console.log('newVal', newVal);
        //this.isLoggedInAsCurrentAccount = this.currentAccountLoggedIn()
        //const routeAddress = _.get(this.$route.params, 'address');
        //this.isLoggedInAsCurrentAccount = routeAddress === this.$store.state.account;
      }
    }
  };
</script>

<style scoped lang="scss">
  h1 .badge {
    position: relative;
    top: -25px;
    right: 5px;
    opacity: 0.75;
    font-size: 0.9rem;
  }

  .editions-wrap {
  }

  .no-assets {
    min-height: 350px;
  }

  .btn-disabled {
    pointer-events: none;
    &:hover {
      cursor: pointer !important;
    }
  }

  @import '../ko-card.scss';
</style>
