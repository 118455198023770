<template>
  <edition-video class="video-container" :edition="edition" v-if="isVideo"></edition-video>
  <edition-image :edition="edition" :id="id" :full="full" :nolazy="nolazy" v-else></edition-image>
</template>

<script>

  import * as _ from 'lodash';
  import EditionImage from './EditionImage';
  import EditionVideo from './EditionVideo';

  export default {
    name: 'editionAsset',
    props: ['edition', 'id', 'full', 'nolazy'],
    components: {EditionVideo, EditionImage},
    data() {
      return {};
    },
    computed: {
      isVideo() {
        // Try work out type based on additional metadata
        if (this.edition && this.edition.attributes && this.edition.attributes.asset_type) {
          return this.edition.attributes.asset_type === 'video/webm';
        }
        // Fall back to trying to work out if its based on file extension
        return (this.edition && this.edition.lowResImg) && _.indexOf(['webm'], _.last(this.edition.lowResImg.split('.')).toLowerCase()) > -1;
      },
    },
    methods: {},
    created() {

    }
  };
</script>

<style scoped lang="scss">

  .video-container {
    height: 20em;
  }
</style>
