<template>
  <router-link :to="{ name: 'legacy-asset', params: { legacyTokenId: edition.tokenId } }"
               class="card-target">
    <div class="card border">
      <edition-image class="card-img-top" :edition="edition" :id="edition.tokenId"/>
      <div class="card-body">
        <p class="card-title">
          {{ edition.artworkName }}
        </p>
      </div>
      <div class="card-footer bg-white no-top-border">
        <div class="mb-2">
          <token-id-badge :token-id="edition.tokenId" v-if="edition && edition.tokenId"></token-id-badge>
        </div>
        <div class="pl-1 artist-name">{{ edition.artist }}</div>
        <div class="pl-1 artist-address text-muted">{{ edition.artistAccount }}</div>
      </div>
    </div>
  </router-link>
</template>
<script>

  import EditionImage from '../generic/EditionImage';
  import TokenIdBadge from '../badges/TokenIdBadge';

  export default {
    name: 'account-card',
    props: ['edition'],
    components: {TokenIdBadge, EditionImage},
  };
</script>

<style scoped lang="scss">


</style>
