<template>
  <div v-if="edition">
    <video width="100%" height="100%" autoplay="autoplay" loop="loop" controls="controls" controlsList="nodownload" muted="muted">
      <source :src="edition.lowResImg" type="video/webm" v-if="isWebM(edition.lowResImg)">
      <source :src="edition.lowResImg" type="video/mp4" v-else-if="isMp4(edition.lowResImg)">
    </video>
  </div>
</template>

<script>
  import * as _ from 'lodash';

  export default {
    name: 'editionVideo',
    props: ['edition'],
    components: {},
    data() {
      return {};
    },
    computed: {},
    methods: {
      isWebM(file) {
        return file && _.last(file.split('.')).toLowerCase() === 'webm';
      },
      isMp4(file) {
        return file && _.last(file.split('.')).toLowerCase() === 'mp4';
      },
    },
    created() {

    }
  };
</script>

<style scoped lang="scss">

</style>
