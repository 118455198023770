<template>
  <lazy-component @show="visibilityChanged" class="row editions-wrap mt-2">

    <div class="col-12" v-if="activity.length === 0">
      <code>No activity found</code>
    </div>

    <table class="table table-borderless">
      <tbody>
      <tr v-for="event in activity" :key="event.id">
        <td class="align-middle" width="25%" v-if="event._args._editionNumber">
          <router-link
            :to="{ name: 'confirmPurchaseSimple', params: { editionNumber: parseInt(event._args._editionNumber) }}">
            <edition-image class="img-thumbnail"
                           :edition="findEdition(parseInt(event._args._editionNumber))"
                           :full="true"/>
          </router-link>
        </td>

        <td class="align-middle">
          <!--{{ mapMobileEvent(event) }}-->
          <code class="">{{ mapEvent(event)}}</code>
          <div v-if="event._args._priceInWei && event._args._priceInWei !== '0'">
            <price-in-eth :value="event._args._priceInWei | toEth"></price-in-eth>
            <u-s-d-price-converter
              :price-in-wei="event._args._priceInWei"
              :usd-exchange-rate="event.exchangeRate.usd">
            </u-s-d-price-converter>
          </div>

          <div v-else-if="event._args._amount && event._args._amount !== '0'">
            <price-in-eth :value="event._args._amount | toEth"></price-in-eth>
            <u-s-d-price-converter
              :price-in-wei="event._args._amount"
              :usd-exchange-rate="event.exchangeRate.usd">
            </u-s-d-price-converter>
          </div>
          <div v-if="event._args._tokenId">
            <router-link :to="{ name: 'edition-token', params: { tokenId: event._args._tokenId.toString() }}"
                         class="badge badge-primary">
              {{ event._args._tokenId.toString() }}
            </router-link>
          </div>

          <div v-else-if="event._args._editionNumber">
            <div class="badge badge-primary">
              {{event._args._editionNumber}}
            </div>
          </div>
        </td>
        <td class="align-middle">
          <small class="d-none d-md-block">{{ event.blockTimestamp | moment('calendar')}}</small>
          <view-transaction-details :transaction="event.transactionHash" class="small"></view-transaction-details>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="col-12">
      <div class="text-muted small mb-5 mt-3 text-right">
        Updated every 5 minutes
        <br/>
        <small>USD prices powered by <a href="https://www.coingecko.com" target="_blank">coingecko</a></small>
      </div>
    </div>

  </lazy-component>
</template>

<script>

  import { mapGetters, mapState } from 'vuex';
  import * as _ from 'lodash';
  import PriceInEth from '../generic/PriceInEth';
  import USDPriceConverter from '../generic/USDPriceConverter';
  import EditionImage from '../generic/EditionImage';
  import ClickableAddress from '../generic/ClickableAddress';
  import ViewTransactionDetails from '../generic/ViewTransactionDetails';
  import * as actions from '../../store/actions';
  import { mapEvent, mapMobileEvent } from '../../services/eventMapper';

  export default {
    name: 'accountActivity',
    components: {
      ViewTransactionDetails,
      ClickableAddress,
      EditionImage,
      USDPriceConverter,
      PriceInEth
    },
    data() {
      return {
        loaded: false,
        activity: []
      };
    },
    computed: {
      ...mapState([
        'account',
        'eventService',
      ]),
      ...mapGetters('kodaV2', [
        'findEdition'
      ]),
    },
    methods: {
      mapEvent,
      mapMobileEvent,
      visibilityChanged() {
        if (!this.loaded) {
          this.loadApiData();
        }
      },
      loadApiData() {
        this.eventService.loadAddressActivity(this.account)
          .then((results) => {
            if (results.success) {
              const {data, resultsAvailable} = results;

              this.resultsAvailable = resultsAvailable;

              _.forEach(data, (result) => {
                this.activity.push(result);
              });

              this.$store.dispatch(`kodaV2/${actions.LOAD_EDITIONS}`, _.uniq(_.map(data, '_args._editionNumber')));
            }
          })
          .finally(() => {
            this.loaded = true;
          });
      }
    },
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';
  @import '../../ko-card.scss';


  .img-thumbnail {
    max-width: 200px;
  }

  .table {
    vertical-align: middle;
  }
</style>
