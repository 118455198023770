<template>
  <span v-if="isOneOfOne && availability !== 0" class="text-muted">1 of 1</span>
  <span v-else-if="availability === 1" class="font-weight-bold text-danger">last edition</span>
  <span v-else-if="availability > 1" class="text-muted">{{ availability }} available</span>
  <span v-else-if="availability == 0" class="text-danger">sold out</span>
</template>

<script>
  export default {
    name: 'availability',
    props: [
      'edition'
    ],
    computed: {
      isOneOfOne() {
        return this.edition.totalAvailable === 1;
      },
      availability() {
        return this.edition.totalAvailable - this.edition.totalSupply;
      }
    }
  };
</script>

<style scoped lang="scss"></style>
