<template>

  <b-form class="ml-4 mr-4 mb-4">

    <span slot="top-right">
      <font-awesome-icon :icon="['fas', 'times']" @click="closePopup()"
                         class="float-right" size="lg">
      </font-awesome-icon>
    </span>

    <!--<h4 class="text-center pb-3 mt-3">Edit your profile</h4>-->
    <h4 class="text-center pb-3 mt-3">Stay in the know</h4>
    <p class="text-muted text-center">
      Share your email and receive notifications about your sales, bids and purchasing activity
    </p>

    <div class="row">
      <div class="col-12">
        <b-form-group id="input-group-username"
                      class="mb-4">
          <slot name="label">
            Username <span class="text-muted small">(optional)</span>
          </slot>
          <input
            class="form-control"
            :class="{
              'is-invalid': results.duplicateName || results.invalidUsername || results.invalidUsernameLength
            }"
            id="input-username"
            v-model="form.username"
            @input="checkDuplicateUsername"
            minlength="4"
            maxlength="25"
            required
          />
          <span class="text-danger" v-if="results.duplicateName">Duplicate username</span>
          <span class="text-danger" v-if="results.invalidUsername">Numbers, letters, underscores only</span>
          <span class="text-danger" v-if="results.invalidUsernameLength">Min 4 characters</span>
        </b-form-group>
      </div>

      <div class="col-12">
        <b-form-group id="input-group-email" class="mb-4"
                      description="We will only contact you about your own activities">
          <slot name="label">
            Email address <span class="text-danger">*</span>
          </slot>
          <input
            class="form-control"
            id="input-email"
            v-model="form.email"
            type="email"
            minlength="8"
            maxlength="75"
            required
          />
        </b-form-group>
      </div>
    </div>

    <!--    <div class="row">-->

    <!--      <div class="col-sm-12 col-md-6">-->
    <!--        <b-form-group id="input-group-website" class="mb-4">-->
    <!--          <slot name="label">-->
    <!--            Website-->
    <!--          </slot>-->
    <!--          <b-form-input-->
    <!--            placeholder="https://notreal.co"-->
    <!--            id="input-website"-->
    <!--            v-model="form.website"-->
    <!--            type="text"-->
    <!--            min="10"-->
    <!--          ></b-form-input>-->
    <!--        </b-form-group>-->
    <!--      </div>-->

    <!--      <div class="col-sm-12 col-md-6">-->
    <!--        <b-form-group id="input-group-twitter" class="mb-4">-->
    <!--          <slot name="label">-->
    <!--            Twitter-->
    <!--          </slot>-->
    <!--          <b-input-group prepend="@">-->
    <!--            <b-form-input-->
    <!--              placeholder="notreal_io"-->
    <!--              id="input-twitter"-->
    <!--              v-model="form.twitter"-->
    <!--              type="text"-->
    <!--              min="4"-->
    <!--              max="1"-->
    <!--            ></b-form-input>-->
    <!--          </b-input-group>-->
    <!--        </b-form-group>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <b-form-group id="input-group-bio" class="mb-4" label="Bio"-->
    <!--                  description="Tell us a bit about yourself">-->
    <!--      <b-form-textarea-->
    <!--        id="input-bio"-->
    <!--        v-model="form.bio"-->
    <!--        maxlength="500"-->
    <!--        type="email"-->
    <!--        rows="4"-->
    <!--        required-->
    <!--      ></b-form-textarea>-->
    <!--    </b-form-group>-->

    <!--    <b-form-group id="input-group-location" label="Location" label-for="input-location" class="mb-4">-->
    <!--      <b-form-input-->
    <!--        id="input-location"-->
    <!--        v-model="form.location"-->
    <!--        min="5"-->
    <!--        max="75"-->
    <!--        required-->
    <!--      ></b-form-input>-->
    <!--    </b-form-group>-->

    <div class="form-group mb-4">
      <div>
        <div>
          <label>Email notifications</label>
        </div>
        <toggle-button
          class="hand-pointer"
          :value="form.enableNotifications"
          :sync="true"
          color="#5165FF"
          @change="form.enableNotifications = !form.enableNotifications"
          :height="25"
          :font-size="12">
        </toggle-button>
        <span v-if="form.enableNotifications">Notifications enabled</span>
        <span v-else>Notifications disabled (not recommended)</span>
      </div>
    </div>

    <b-button @click="saveProfile()"
              type="button"
              variant="primary"
              :disabled="!isValidForm">
      Save profile
    </b-button>

    <b-button @click="cancelPopup()" variant="link" class="float-right">Cancel</b-button>

  </b-form>

</template>

<script>

  import {mapGetters, mapState} from 'vuex';
  import * as actions from '../../store/actions';
  import {ToggleButton} from 'vue-js-toggle-button';
  import * as _ from 'lodash';
  import {BForm, BFormGroup, BFormInput, BFormTextarea, BButton} from 'bootstrap-vue';

  export default {
    name: 'ManageUserProfile',
    components: {
      ToggleButton
    },
    props: ['profile'],
    data() {
      return {
        form: {
          email: null,
          username: null,
          location: null,
          enableNotifications: true
        },
        results: {
          duplicateName: false
        }
      };
    },
    methods: {
      saveProfile() {
        // console.log('this.form', this.form);
        this.$ga.event('manage-profile-popup', 'popup-displayed', 'save-profile');
        this.$store.dispatch(`auth/${actions.WEB3_AUTH_CHECK_DUPLICATE_USERNAME}`, this.form.username)
          .then(({success}) => {
            this.results.duplicateName = success !== true;

            if (!this.results.duplicateName) {
              this.$store.dispatch(`auth/${actions.WEB3_AUTH_SAVE_PROFILE}`, {
                username: this.form.username,
                email: this.form.email,
                enableNotifications: this.form.enableNotifications,
                // location: this.form.location,
                // bio: this.form.bio,
                // twitter: this.form.twitter,
                // website: this.form.website,
              })
                .then((result) => {
                  console.log('saved', result);
                  this.$emit('close');
                })
                .catch((error) => {
                  alert('failure to save!');
                  console.log(error);
                });
            }
          });
      },
      closePopup() {
        this.$ga.event('manage-profile-popup', 'popup-displayed', 'top-right-close');
        this.$emit('close');
      },
      cancelPopup() {
        this.$ga.event('manage-profile-popup', 'popup-displayed', 'cancel-close');
        this.$emit('close');
      }
    },
    computed: {
      isValidForm() {
        const trimUsername = _.trim(this.form.username);
        if (_.size(trimUsername) < 0 && _.size(trimUsername) < 4) {
          return false;
        }
        if (_.size(_.trim(this.form.email)) < 8) {
          return false;
        }
        /*eslint no-useless-escape: "off"*/
        const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegEx.test(this.form.email.toLowerCase()) &&
          !this.results.duplicateName &&
          !this.results.invalidUsername &&
          !this.results.invalidUsernameLength;
      }
    },
    watch: {
      'form.username': function (newVal, oldVal) {
        if (newVal) {
          //0-9, a-z, A-Z, (space), (underscore), (dash)
          const pattern = /^[a-zA-Z0-9 _-]+$/;
          this.results.invalidUsername = !pattern.test(newVal);
        }
        this.results.invalidUsernameLength = (newVal && newVal.trim().length < 4);
      }
    },
    created() {
      // Assigned debounce methods in created to fix 'this' issu e
      this.checkDuplicateUsername = _.debounce(() => {
        this.$store.dispatch(`auth/${actions.WEB3_AUTH_CHECK_DUPLICATE_USERNAME}`, this.form.username)
          .then(({success}) => {
            this.results.duplicateName = success !== true;
          });
      }, 1000);
    },
    mounted() {
      if (this.profile) {
        this.form = {
          ...this.profile
        };
        if (this.form.enableNotifications === undefined) {
          this.form.enableNotifications = true;
        }
      }
    },
  };
</script>

<style scoped lang="scss">
  @import '../../ko-card.scss';

</style>
