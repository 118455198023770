<template>
  <span>
    <address-icon :eth-address="ethAddress"></address-icon>
    <span class="badge badge-primary" v-if="totalOwnedTokens > 0">{{ totalOwnedTokens }}</span>
  </span>
</template>

<script>

  import AddressIcon from '../generic/AddressIcon';

  export default {
    name: 'AccountProfile',
    props: ['ethAddress', 'totalOwnedTokens'],
    components: {
      AddressIcon
    },
    data() {
      return {};
    },
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';

</style>
