<template>
  <span v-if="ethAddress">
    <img class="img-fluid icon-normal rounded-circle artist-avatar" :src="createIcon"/>
  </span>
</template>

<script>
  import {toDataUrl} from 'myetherwallet-blockies';

  export default {
    name: 'BlockiesIcon',
    props: {
      ethAddress: {
        type: String
      }
    },
    computed: {
      createIcon: function () {
        return toDataUrl(this.ethAddress);
      }
    }
  };
</script>

<style>
  .icon-normal {
    height: 32px;
    width: 32px;
  }
</style>
