<template>
  <div class="card" :id="'gallery_card_' + edition.edition">

    <router-link :to="routeData()" class="card-target" v-if="edition">
      <edition-asset :edition="edition" :id="edition.edition" :nolazy="nolazy">
      </edition-asset>
    </router-link>

    <div class="card-body py-1">
      <div class="row">
        <div class="col-3">
          <router-link :to="routeData()" class="card-target" v-if="edition">
            <p class="card-title mb-1 mt-2 card-edition-name">
              <creative-challenge-label :attributes="edition.attributes"></creative-challenge-label>
              <span>{{ edition.name }}</span>
              <!--<availability class="float-right small"  :edition="edition"></availability>-->
            </p>
          </router-link>
        </div>
        <div class="col">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="card-footer no-top-border bg-white py-1">
      <div class="row" v-if="edition && !edition.tokenId">
        <div class="col d-none">
          <edition-artist-names :edition="edition" :show-icon="false"></edition-artist-names>
        </div>
        <div class="col text-right" v-if="edition && edition.tokenId">
          <p class="card-text">
            <!--
            <router-link :to="routeData()" class="card-target" v-if="edition">
              <availability :total-available="edition.totalAvailable" :total-supply="edition.totalSupply"></availability>
            </router-link>
            -->
          </p>
        </div>
      </div>
      <div class="row mb-2" v-if="edition && edition.tokenId">
        <div class="col">
          <token-id-badge :token-id="edition.tokenId"></token-id-badge>
        </div>
        <div class="col text-right">
          <x-of-x-badge :edition="edition"></x-of-x-badge>
        </div>
      </div>
      <div class="row">
        <div class="col-8" v-visible="showPrice">
          <router-link :to="routeData()" class="card-target" v-if="edition && !edition.tokenId">
            <p class="card-text card-edition-price">
              <price-in-eth :value="edition.priceInEther | to4Dp"></price-in-eth>
            </p>
          </router-link>
        </div>
        <div class="col-4 text-right pt-1">
          <LikeIconButton :edition-number="edition.edition" :nolazy="nolazy"></LikeIconButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import Availability from '../v2/Availability';
  import CreativeChallengeLabel from '../../components/generic/CreativeChallengeLabel';
  import PriceInEth from '../generic/PriceInEth';
  import LikeIconButton from '../likes/LikeIconButton';
  import TokenIdBadge from '../badges/TokenIdBadge';
  import XOfXBadge from '../badges/XOfXBadge';
  import EditionAsset from '../generic/EditionAsset';
  import EditionArtistNames from './EditionArtistNames';

  export default {
    name: 'gallery-card',
    props: ['edition', 'editionNumber', 'nolazy', 'showPrice'],
    components: {
      EditionArtistNames,
      EditionAsset,
      LikeIconButton,
      XOfXBadge,
      TokenIdBadge,
      Availability,
      CreativeChallengeLabel,
      PriceInEth,
    },
    computed: {
      ...mapGetters([
        'findArtistsForAddress'
      ])
    },
    methods: {
      routeData: function () {
        if (this.edition) {
          if (this.edition.tokenId) {
            return {name: 'edition-token', params: {tokenId: this.edition.tokenId}, props: {edition: this.edition}};
          }

          return {name: 'confirmPurchaseSimple', params: {editionNumber: this.edition.edition}};
        }
        return {};
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';

  .card-artist-link a {
    color: $realBlack;
  }

  .card-edition-name {
    color: $realBlack;
  }

  .card-edition-price {
    color: $realBlack;
  }

  .high-res {
    position: absolute;
    top: -5px;
    opacity: 0.9;
  }

  @import '../../ko-card.scss';

</style>
