<template>
  <span v-if="ethAddress">
    <a class="artist-link text-decoration-none" :href="buildLink" target="_blank">
      <blockies-icon :eth-address="ethAddress"></blockies-icon>
    </a>
  </span>
</template>

<script>
  import BlockiesIcon from './BlockiesIcon';
  import {mapState} from 'vuex';

  export default {
    name: 'ClickableAddressIcon',
    components: {BlockiesIcon},
    props: {
      ethAddress: {
        type: String
      }
    },
    computed: {
      ...mapState([
        'etherscanBase',
      ]),
      buildLink: function () {
        return `${this.etherscanBase}/address/${this.ethAddress}`;
      }
    }
  };
</script>

<style>
  .icon-normal {
    height: 32px;
    width: 32px;
  }
</style>
