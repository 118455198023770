<template>
  <p v-if="!account">
    We cant see your account, have you <a href="#" @click="web3ConnectLogin">signed</a> in to your collection yet?
  </p>
</template>

<script>
  import {mapState} from 'vuex';
  import web3Connect from '../../services/web3connect';

  export default {
    name: 'AccountNotFoundMsg',
    computed: {
      ...mapState([
        'account',
      ]),
    },
    methods: {
      async web3ConnectLogin() {
        // open modal on button click
        await web3Connect.toggleModal();
      }
    }
  };
</script>

<style scoped lang="scss">

</style>

