<template>
  <span>
    <code>
      {{ tokenId }}
    </code>
  </span>
</template>

<script>
  export default {
    name: 'TokenIdBadge',
    props: ['tokenId']
  };
</script>

<style scoped lang="scss">
</style>
