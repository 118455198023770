<template>
  <span v-if="ethAddress">
    <blockies-icon :eth-address="ethAddress"></blockies-icon>
    <clickable-address :eth-address="ethAddress"></clickable-address>
  </span>
</template>

<script>
  import ClickableAddress from './ClickableAddress';
  import BlockiesIcon from './BlockiesIcon';

  export default {
    name: 'addressIcon',
    components: {BlockiesIcon, ClickableAddress},
    props: {
      ethAddress: {
        type: String
      }
    },
  };
</script>

<style>
  .icon-normal {
    height: 32px;
    width: 32px;
  }
</style>
