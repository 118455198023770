export const PAGES = {
  COMPLETE_PURCHASE: 'COMPLETE_PURCHASE',
  CONFIRM_PURCHASE: 'CONFIRM_PURCHASE',
  FEED: 'FEED',
  GALLERY: 'GALLERY',
  ACCOUNT: 'ACCOUNT',
  ARTISTS: 'ARTISTS',
  LEGACY_ASSET: 'LEGACY_ASSET',
  EDITION_TOKEN_OVERVIEW: 'EDITION_TOKEN_OVERVIEW',
  ACTIVITY: 'ACTIVITY',
};
