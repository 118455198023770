<template>
  <span>
    <div v-if="edition.secondCommissionSplit && edition.secondCommissionSplit.recipient">
      <!-- start: Icon -->
      <router-link v-if="showIcon"
                   :to="{ name: 'artist', params: { artistAccount: artistAddress } }" class="artist-link">
        <img 
          width="1" height="1"
          :src="(findArtistsForAddress(edition.artistAccount) || {}).logo" 
          class="artist-avatar"/>
      </router-link>
      <router-link v-if="showIcon && findArtistsForAddress(edition.secondCommissionSplit.recipient)"
                   :to="{ name: 'artist', params: { artistAccount: edition.secondCommissionSplit.recipient } }"
                   class="ml-1 artist-link">
        <img 
          width="1" height="1"
          :src="(findArtistsForAddress(edition.secondCommissionSplit.recipient) || {}).logo"
          class="artist-avatar"/>
      </router-link>
      <router-link v-else-if="showIcon"
                   :to="{ name: 'artist', params: { artistAccount: artistAddress } }"
                   class="artist-link">
        <blockies-icon :eth-address="edition.secondCommissionSplit.recipient"></blockies-icon>
      </router-link>
      <!-- end: Icon -->

      <!-- start: Names -->
      <router-link :to="{ name: 'artist', params: { artistAccount: artistAddress } }" class="ml-1 artist-link">
      <span class="artist-name-lg">{{ (findArtistsForAddress(edition.artistAccount) || {} ).name }}</span>
      </router-link>
      <span class="text-muted"> & </span>

      <span class="artist-name-lg" v-if="findArtistsForAddress(edition.secondCommissionSplit.recipient)">
        <router-link :to="{ name: 'artist', params: { artistAccount: edition.secondCommissionSplit.recipient } }"
                     class="artist-link">
          {{ findArtistsForAddress(edition.secondCommissionSplit.recipient).name }}
        </router-link>
      </span>
      <router-link v-else :to="{ name: 'artist', params: { artistAccount: artistAddress } }"
                   class="artist-link">
        {{edition.secondCommissionSplit.recipient | dotDotDot}}
      </router-link>
      <!-- end: Names -->
    </diV>

    <!-- if single artist -->
    <div v-else>
      <router-link :to="{ name: 'artist', params: { artistAccount: artistAddress } }" class="artist-link">
        <img 
          width="1" height="1"
          :src="(findArtistsForAddress(edition.artistAccount) || {}).logo" 
          class="artist-avatar" 
          alt="artist-logo"/>
        <span class="pl-1 artist-name-lg">{{ (findArtistsForAddress(edition.artistAccount) || {}).name }}</span>
      </router-link>
    </div>

  </span>
</template>

<script>
  import AddressIcon from '../generic/AddressIcon';
  import {mapGetters} from 'vuex';
  import BlockiesIcon from '../generic/BlockiesIcon';
  import ClickableAddress from '../generic/ClickableAddress';
  import ClickableAddressIcon from '../generic/ClickableAddressIcon';
  import * as _ from 'lodash';

  export default {
    name: 'EditionArtistNames',
    components: {ClickableAddressIcon, ClickableAddress, BlockiesIcon, AddressIcon},
    props: {
      edition: {
        type: Object
      },
      showIcon: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters([
        'findArtistsForAddress'
      ]),
      artist() {
        return this.findArtistsForAddress(this.edition.artistAccount) || {};
      },
      artistAddress() {
        const artist = this.artist;
        if (!artist) {
          return {};
        }
        if (_.isArray(artist.ethAddress)) {
          return artist.ethAddress[0];
        }
        return artist.ethAddress;
      },
    }
  };
</script>

<style scoped lang="scss">

  .primary-artist {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;

  }

  .secondary-artist {
  }

  a:hover {
    text-decoration: none;
  }
</style>
