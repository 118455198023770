<template>
  <div class="like-card-container">
    <component :is="nolazy ? 'div' : 'lazy-component'" @show="visibilityChanged">

      <span v-if="hasLoaded && account">

        <font-awesome-icon :icon="['far', 'heart']" class="hand-pointer"
                           v-if="!like" :disabled="working"
                           size="lg"
                           @click="registerLike">
        </font-awesome-icon>

        <font-awesome-icon :icon="['fas', 'heart']" class="liked-icon hand-pointer"
                           v-if="like" :disabled="working"
                           size="lg"
                           @click="registerLike">
        </font-awesome-icon>
      </span>

      <!--Account locked -->
      <span v-if="hasLoaded && !account">
          <font-awesome-icon :icon="['far', 'heart']" class="hand-pointer"
                             v-b-tooltip.hover="'Sign in to like this edition'"
                             size="lg"
                             @click="login">
          </font-awesome-icon>
      </span>

      <span v-if="hasLoaded"
            class="ml-1"
            v-bind:class="{ 'liked-icon': like, 'text-muted': !like }">
        <span>{{totalEditionLikes || ''}}</span>
      </span>

    </component>


  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import * as actions from '../../store/actions';
  import { VBTooltip } from 'bootstrap-vue';

  export default {
    directives: {'b-tooltip': VBTooltip},
    name: 'likeIconButton',
    props: ['editionNumber', 'nolazy'],
    data() {
      return {
        count: null,
        like: false,
        working: false,
        hasLoaded: false,
        totalEditionLikes: null,
        loaded: false
      };
    },
    computed: {
      ...mapState([
        'account',
        'likesService',
      ]),
    },
    methods: {
      // We only trigger the loading when we become visible to save the extra load
      visibilityChanged(component) {

        if (!this.loaded && this.likesService && component) {
          this.loaded = true;

          // If we have both then look up for address
          if (this.account) {
            this.likesService.getLikesForEditionAndAccount(this.editionNumber, this.account)
              .then(({like, totalEditionLikes}) => {
                this.hasLoaded = true;
                this.like = like;
                this.totalEditionLikes = totalEditionLikes;
              });
          } else {
            //Otherwise assume not logged in and look up for edition only
            this.likesService.getLikesForEdition(this.editionNumber)
              .then(({like, totalEditionLikes}) => {
                this.hasLoaded = true;
                this.like = like;
                this.totalEditionLikes = totalEditionLikes;
                //console.log(this.account)
              });
          }
        }
      },
      registerLike() {
        // Attempt to prevent smashing the buttpn
        if (this.working) {
          return;
        }

        if (this.account && this.editionNumber) {
          this.working = true;

          // Immediately toggle
          this.like
            ? this.totalEditionLikes -= 1
            : this.totalEditionLikes += 1;

          // Inverse
          this.like = !this.like;

          this.$ga.event('likes', 'register', 'like-icon');

          // Fire off request
          this.likesService.registerLike(this.editionNumber, this.account)
            .then(({like, totalEditionLikes}) => {
              this.like = like;
              this.totalEditionLikes = totalEditionLikes;
            })
            .finally(() => {
              this.working = false;
            });
        }
      },
      login() {
        this.$store.dispatch(actions.WALLET_CONNECT_LOGIN);
      }
    },
    created() {
      if (this.nolazy) { this.visibilityChanged(true) }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';

  .hand-pointer {
    cursor: pointer;
  }

  .liked-icon {
    color: $liked-action;
  }

  .like-card-container {
    height: 21px;
  }

</style>
