<template>
  <span v-if="transaction">
    <a class="small btn-link text-info" :href="buildLink" target="_blank">
      <slot>[view tx]</slot>
    </a>
  </span>
</template>

<script>
  /* global web3:true */
  import {mapGetters, mapState} from 'vuex';

  export default {
    name: 'viewTransactionDetails',
    components: {},
    props: [
      'transaction',
      'wallet',
      'address',
      'suffix'
    ],
    computed: {
      ...mapState([
        'etherscanBase',
        'etherscanBaseWallet',
      ]),
      buildLink: function () {
        const base = this.wallet ? this.etherscanBaseWallet : this.etherscanBase
        if (this.address) {
          return `${base}/address/${this.address}/${this.suffix || ""}`;
        } else {
          return `${base}/tx/${this.transaction}`;
        }
      }
    }
  };
</script>

<style>

</style>
